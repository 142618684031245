.Home {
  .lander {
    padding: 80px 0;
    text-align: center;

    h1 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }

    p {
      color: #999;
    }

    div {
      padding-top: 20px;
      a:first-child {
        margin-right: 20px;
      }
    }
  }

  .items {
    h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      overflow: hidden;
      line-height: 1.5;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      color: #666;
    }
  }
}